import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type Plan = {
  name: string; 
  description: string;
  benefits: Array<string>;
  monthlyPrice?: string;
  yearlyPrice?: string;
  eventPrice?: string;
  paymentTypeText?: string;
}

type GetSubscriptionResponse = {
  id: string;
  type: string;
  attributes: {
    name: string;
    price: string;
    description: string;
    sub_title: string;
    subscription_type: "yearly" | "monthly";
    valid_up_to: string;
    expired: boolean;
    image_link: string | null,
    subscribed: boolean;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  plans: Array<Plan>;
  userPlan: Plan;
  userType: "User" | "Business"
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getListCallId: any;
  getBusinessBasicSubscriptions: string = "";
  getBusinessPremiumSubscriptions: string = "";
  getBusinessFounderSubscriptions: string = "";
  getBusinessSpecialEventSubscriptions: string = "";
  getUserInformationCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      plans: [],
      userType: "Business",
      userPlan: {} as Plan,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token, plans: [] }, () => {
        this.getUserInformation();
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getUserInformationCallId) {
          if (responseJson.data.attributes.type === "User") {
            this.fetchSubscriptionsList();
          } else {
            this.fetchBusinessBasicSubscriptions();
            this.fetchBusinessPremiumSubscriptions();
            this.fetchBusinessFounderSubscriptions();
            this.fetchBusinessSpecialEventSubscriptions();
          }
          this.setState({
            userType: responseJson.data.attributes.type
          })
        } else if (apiRequestCallId === this.getListCallId) {
          const subscriptions = responseJson.data as GetSubscriptionResponse[];
          const userPlan = {
            name: subscriptions[0].attributes.name,
            description: subscriptions[0].attributes.sub_title,
            benefits: [subscriptions[0].attributes.description],
            paymentTypeText: "Free 1 week trail",
            yearlyPrice: subscriptions.find(sub => sub.attributes.subscription_type === "yearly")?.attributes.price,
          }
          this.setState({
            userPlan: userPlan
          })
        } else if (apiRequestCallId === this.getBusinessBasicSubscriptions) {
          const subscriptions = responseJson.data as GetSubscriptionResponse[];
          const basicPlan = {
            name: subscriptions[0].attributes.name,
            description: subscriptions[0].attributes.sub_title,
            benefits: subscriptions[0].attributes.description.split(','),
            monthlyPrice: subscriptions.find(sub => sub.attributes.subscription_type === "monthly")?.attributes.price,
            yearlyPrice: subscriptions.find(sub => sub.attributes.subscription_type === "yearly")?.attributes.price
          }
          this.setState({
            plans: [...this.state.plans, basicPlan]
          })
        } else if (apiRequestCallId === this.getBusinessPremiumSubscriptions) {
          const subscriptions = responseJson.data as GetSubscriptionResponse[];
          const premiumPlan = {
            name: subscriptions[0].attributes.name,
            description: subscriptions[0].attributes.sub_title,
            benefits: subscriptions[0].attributes.description.split(','),
            monthlyPrice: subscriptions.find(sub => sub.attributes.subscription_type === "monthly")?.attributes.price,
            yearlyPrice: subscriptions.find(sub => sub.attributes.subscription_type === "yearly")?.attributes.price,
          }
          this.setState({
            plans: [...this.state.plans, premiumPlan]
          })
        } else if (apiRequestCallId === this.getBusinessFounderSubscriptions) {
          const subscriptions = responseJson.data as GetSubscriptionResponse[];
          const founderPlan = {
            name: subscriptions[0].attributes.name,
            description: subscriptions[0].attributes.sub_title,
            benefits: subscriptions[0].attributes.description.split(','),
            paymentTypeText: "One-time payment of",
            yearlyPrice: subscriptions.find(sub => sub.attributes.subscription_type === "yearly")?.attributes.price
          }
          this.setState({
            plans: [...this.state.plans, founderPlan]
          })
        } else if (apiRequestCallId === this.getBusinessSpecialEventSubscriptions) {
          const subscriptions = responseJson.data as GetSubscriptionResponse[];
          const eventPlan = {
            name: subscriptions[0].attributes.name,
            description: subscriptions[0].attributes.sub_title,
            benefits: subscriptions[0].attributes.description.split(','),
            eventPrice: subscriptions[0].attributes.price
          }
          this.setState({
            plans: [...this.state.plans, eventPlan]
          })
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item: any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SubscriptionDetails"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
    // Customizable Area End
  }

  // Customizable Area Start
  btnGoBackProps = {
    onPress: () => this.goToHome()
  }

  goToHome() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goToBillingInformation(plan: Plan) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Subscriptionbilling2");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
   
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), plan);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg);

  }

  getUserInformation = () => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserInformationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserInformationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchBusinessBasicSubscriptions = () => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessBasicSubscriptions = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBusinessBasicSubscriptions
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchBusinessPremiumSubscriptions = () => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessPremiumSubscriptions = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBusinessPremiumSubscriptions
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchBusinessFounderSubscriptions = () => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessFounderSubscriptions = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBusinessFounderSubscriptions
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchBusinessSpecialEventSubscriptions = () => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessSpecialEventSubscriptions = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBusinessSpecialEventSubscriptions
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
